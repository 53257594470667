/* +------------------- +
   | Table of contents: |
   +------------------- + */
/* General settings
Special classes
Navbar
Cross effect
Home
Parallax settings
Footer */
$true-orange: rgb(255, 140, 0);
$orange: rgb(238, 185, 78);
$yellow-orange: rgb(241, 188, 46);
$green: rgb(173, 207, 47);
$light-grey: rgb(228, 228, 228);
$mid-grey: rgb(112, 112, 112);
$grey: rgb(34, 34, 34);
$white: rgb(255, 255, 255);
$black: #000;

$gold: #cbac74;
$dark: #2C2B2A;

/* -- General settings -- */
body{
    margin: 0;
    overflow-x: hidden !important;
    font-family: 'Muli';
    color: var(--grey);
    background-color: var(--grey);
    scroll-behavior: smooth;
}
::selection{
    background-color: var(--green);
    /* background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%); */
}
:root{
    --transparent: transparent;
    --true-orange: rgb(255, 140, 0);
    --true-orange-: 255, 140, 0;
    --orange: rgb(238, 185, 78);
    --orange-: 238, 185, 78;
    --yellow-orange: rgb(241, 188, 46);
    --yellow-orange-: 241, 188, 46;
    --green: rgb(173, 207, 47);
    --green-: 173, 207, 47;
    --light-grey: rgb(228, 228, 228);
    --light-grey-: 228, 228, 228;
    --mid-grey: rgb(112, 112, 112);
    --mid-grey-: 112, 112, 112;
    --grey: rgb(34, 34, 34);
    --grey-: 34, 34, 34;
    --white: rgb(255, 255, 255);
    --white-: 255, 255, 255;
    
    --nav-height: 6rem;
    --nav-height-m: 5rem;
    --nav-height-s: 4rem;
}
@font-face{
    font-family: 'Muli Extra-Light';
    src: 
    url(webfonts/muli/muli-extralight-webfont.woff),
    url(webfonts/muli/muli-extralight-webfont.woff2);
}
@font-face{
    font-family: 'Muli Extra-Light-Italic';
    src: 
    url(webfonts/muli/muli-extralightitalic-webfont.woff),
    url(webfonts/muli/muli-extralightitalic-webfont.woff2);
}
@font-face{
    font-family: 'Muli Light';
    src: 
    url(webfonts/muli/muli-light-webfont.woff),
    url(webfonts/muli/muli-light-webfont.woff2);
}
@font-face{
    font-family: 'Muli Light-Italic';
    src: 
    url(webfonts/muli/muli-lightitalic-webfont.woff),
    url(webfonts/muli/muli-lightitalic-webfont.woff2);
}
@font-face{
    font-family: 'Muli';
    src: 
    url(webfonts/muli/muli-webfont.woff),
    url(webfonts/muli/muli-webfont.woff2);
}
@font-face{
  font-family: 'Muli Italic';
  src: 
      url(webfonts/muli/muli-italic-webfont.woff), 
      url(webfonts/muli/muli-italic-webfont.woff2);
}
@font-face{
    font-family: 'Muli Semi-Bold';
    src: 
        url(webfonts/muli/muli-semibold-webfont.woff), 
        url(webfonts/muli/muli-semibold-webfont.woff2);
  }
  @font-face{
    font-family: 'Muli Semi-Bold-Italic';
    src: 
        url(webfonts/muli/muli-semi-bolditalic-webfont.woff), 
        url(webfonts/muli/muli-semi-bolditalic-webfont.woff2);
  }
  @font-face{
    font-family: 'Muli Bold';
    src: 
        url(webfonts/muli/muli-bold-webfont.woff), 
        url(webfonts/muli/muli-bold-webfont.woff2);
  }
  @font-face{
    font-family: 'Muli Bold-Italic';
    src: 
        url(webfonts/muli/muli-bolditalic-webfont.woff), 
        url(webfonts/muli/muli-bolditalic-webfont.woff2);
  }
p{
    font-size: 1.4rem;
}
/* .row{
    margin: 0;
}
.col{
    padding: 0 !important;
} */

/* -- Special classes -- */
.h-inherit{
    height: inherit !important;
}
.nav-scrolled{
    background-color: rgba(255, 255, 255, 0.97) !important;
    /* background: rgba(255, 255, 255, 0.97) !important; */
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.44) !important;
}
.fade-in{
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.7s ease-out;
}
.faded-in{
    opacity: 1 !important;
    transform: translateY(0) !important;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.full-width {
    width: 100%;
}
.float-left {
    -webkit-animation: float-left 0.5s backwards;
    animation: float-left 0.5s backwards;
    opacity: 1;
}

/* -- Navbar -- */
#nav {
    /* max-height: 6rem; */
    width: 100%;
    height: var(--nav-height);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    padding: 0;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.25); */
    // border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    box-shadow: none;
    background-color: var(--transparent);
    z-index: 1001;
    transition: 0.3s ease-out;
    
    #nav-ul{
        position: absolute;
        right: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        height: var(--nav-height);
        width: 50vw;
        max-width: 650px;
        min-width: 432px;
        transition: 0.3s ease-out;
        
        li a{
            text-decoration: none;
        }

        a:hover{
            background: transparent;
        }
    }
}
#home-shortcut{
    display: flex;
    position: absolute;
    left: 10%;
    align-self: center;

    .logo-img{
        height: 4rem;
        width: auto;
    }
}
.logo-img{
    transition: all 0.5s ease;
}
// #home-shortcut .logo-img{
//     height: 4rem;
//     width: auto;
// }
.social-link{
    display: flex;
    padding: 0;
}
.social-link > i{
    height: unset;
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	transition: 0.3s ease-out;
}
.nav-scrolled .social-link > i{
    color: rgba(22, 22, 22, 0.7);
}
.social-link > i:hover{
    color: white;
    -webkit-transition: 0.3s;
	-moz-transition: 0.3s;
    transition: 0.3s ease-out;
    box-shadow: 0 2px 17px 0 rgba(22, 22, 22, 0.4);
}
.nav-scrolled  .social-link > i:hover{
    color: rgb(22, 22, 22);
    box-shadow: 0 2px 17px 0 var(--green);
}
#mobile-bars{
    display: none;
    position: absolute;
    right: 40px;
    align-self: center;
    height: unset;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: all 0.3s ease-out;

    &::selection {
        background-color: transparent;
    }

    &:hover {
        box-shadow: 0 0 17px rgba(0,0,0,0.5);
        background: rgba(0,0,0,0.2);
    }
}
.nav-scrolled #mobile-bars{
    color: rgba(22, 22, 22, 0.7);
}
.change#mobile-bars {
    opacity: 0;
}
.c-logo{
    fill: #ff7f50;
    stroke: #ff7f50;
}

// -- Mobile Menu --
#mobile-menu-container {
    position: fixed;
    display: none;
    opacity: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0,0,0,0.9);
    z-index: 10001;
    transition: 0.3s all 0.3s ease-out;

    #mobile-nav-link-list {
        position: unset;
        width: 100%;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        li {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            &::after {
                content: "";
                position: fixed;
                border-bottom: 1px solid rgba(var(--light-grey-), 0.5);
                width: 85%;
            }

            a {
                height: 100%;
                width: 100%;
                font-size: 1.2rem;
                color: var(--white);
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: unset;
                transition: all 0.3s ease-out;

                &:hover {
                    color: transparent;
                    letter-spacing: 0.2rem;
                }

                &::after {
                    content: attr(li-text);
                    letter-spacing: -0.2rem;
                    position: fixed;
                    color: var(--orange);
                    font-size: 5rem;
                    opacity: 0;
                    transform: scale(0.95);
                    transition: all 0.3s ease-out;
                }
                &:hover::after {
                    letter-spacing: 0.3rem;
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }

    #close-mobile-menu {
        position: fixed;
        transform: translate(50%, -50%) rotate(45deg);
        right: 0;
        background-color: var(--orange);
        height: 12rem;
        width: 12rem;
        cursor: pointer;
        transition: all 0.3s ease-out;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        span {
            color: $white;
            margin-bottom: 1rem;
            transition: all 0.3s ease-out;
        }

        &:hover {
            transform: translate(50%, -50%) rotate(45deg) scale(1.2);

            span {
                text-shadow: 3px 3px 12px rgba(0,0,0,0.8);
            }
        }

        
    }
}
.change#mobile-menu-container {
    transition: all 0.5s ease-out;
    display: block;
    opacity: 1;
}
/* -- Cross effect -- */
.cl-effect-18 {
	position: relative;
	z-index: 1;
    
    a {
        padding: 0 5px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        transition: color 0.3s;
        
        transition: all 0.3s;

        &::before {
            -webkit-transform: translateY(-20px);
            -moz-transform: translateY(-20px);
            transform: translateY(-20px);
        }
        &::after {
            -webkit-transform: translateY(20px);
            -moz-transform: translateY(20px);
            transform: translateY(20px);
        }
        &::before,
        &::after {
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            height: 2px;
            margin-top: -1px;
            background: rgba(255, 255, 255, 0.5);
            content: '';
            z-index: -1;
            -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
            -moz-transition: -moz-transform 0.3s, opacity 0.3s;
            transition: transform 0.3s, opacity 0.3s;
            pointer-events: none;
        }

        &:hover,
        &:focus {
            color: #fff;
            /* text-shadow: 0 0 17px var(--grey); */
        }
        &:hover::before,
        &:hover::after,
        &:focus::before,
        &:focus::after {
            opacity: 0.7;
        }
        &:hover::before,
        &:focus::before {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:hover::after,
        &:focus::after {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
}
.nav-scrolled {
    .cl-effect-18 {
        a {
            color: rgba(22, 22, 22, 0.7);

            &::before,
            &::after {
                background: rgba(22, 22, 22, 0.0);
                transition: 0.3s ease-in-out;
            }
            &:hover,
            &:focus {
                color: rgba(22, 22, 22, 1);
            }
            &:hover::before,
            &:hover::after,
            &:focus::before,
            &:focus::after {
                background: rgba(var(--green-), 0.6);
                transition: 0.3s ease-in-out;
            }
        }
    }
}



/* -- Home -- */
.c-content{
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* padding: 3rem 10% 3rem 10%; */
    background-color: white;
    overflow: hidden;
    /* background-color: #0cbaba;
    background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%); */
}
.c-content-pad{
    padding: 3rem 10% 3rem 10%;
}
.c-content-hor-pad{
    padding: 0 10%;
}
.c-content-vert-pad{
    padding: 3rem 0 3rem 0;
}

.text{
    width: 100%;
}
/* .about-text{
    width: 70vw;
} */

/* .content .text{
    display: flex;
    flex-direction: column;
    width: 100%;
} */

section.text-section {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 100;
}

#section-about-wrapper {
    display: flex;
    flex-direction: column;
    #o {
        padding-bottom: 2rem;
        // &::before {
        //     content: url(\resources\images\1.svg);
        //     // background-image: url(resources/images/landing-shape.svg);
        //     width: 100vw;
        //     height: 10vh;
        //     background-size: 100% 100%;
        //     background-repeat: no-repeat;
        //     transform: translate(10px, -100px);
        // }
    }
}
.section-header {
    text-align: center;
    font-weight: bold;
    position: inherit;
    margin-bottom: 5rem;
    z-index: 1;
    
    &::after {
        position: absolute;
        content: '';
        width: 6rem;
        height: 0.5rem;
        left: 50%;
        transform: translate(-50%, 5rem);
        background: var(--orange);
        transition: all 0.3s ease-out;
    }
}
#about-text-wrapper{
    width: 60vw;
}
#about-logo-img-wrapper{
    position: absolute;
    right: -11rem;
}
#about-logo-img{
    opacity: 0.5;
    transition: all 0.6s ease-out;
}
#about-tools-img{
    position: absolute;
    height: 6rem;
    margin-left: -10%;
    transform: translateX(-40%) rotate(90deg);
    display: block;
}
#offer-text-wrapper{
    position: inherit;
    width: 60vw;
    margin: 0 0 0 auto;
}
#offer-img-wrapper{
    position: absolute;
    left: 5%;
}
#offer-img{
    transition: all 0.6s ease-out;
    opacity: 0.7;
}
#offer-tools-img{
    position: absolute;
    height: 9rem;
    right: 0;
    transform: translateX(40%) rotate(-90deg);
    display: block;
}
#gallery-text{
    width: 20vw;
}
#gallery-text-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
#landing-img{
    transform: scaleX(-1);
    position: absolute;
    top: 0px;
    right: 0px;
}
.landing-c-btn{
    /* text-transform: none; */
    background-color: white;
    color: var(--grey);
    transition: 0.3s ease-in-out;
    margin: 2rem 0 2rem 0;
    border-radius: 15px;

    &:hover,
    &:focus {
        /* text-transform: none; */
        background-color: var(--green);
        color: white;
        box-shadow: 0 2px 17px 0 var(--green);
        transition: 0.3s ease-in-out;
    }
}
.offer-c-btn{
    background-color: var(--orange);
    color: var(--white);
    transition: 0.6s ease-in-out;
    margin: 2rem 0 2rem 0;
    border-radius: 15px;

    &:hover,
    &:focus {
        background-color: var(--green);
        color: white;
        box-shadow: 0 2px 17px 0 var(--green);
        transition: 0.3s ease-in-out;
    }
}
// .offer-c-btn:hover, .offer-c-btn:focus{
// }

.inner-content{
    position: relative;
    /* top: 50vh; */
    right: 5vw;
    /* height: 80%; */
    width: 30vw;
    border: 1px solid #ff7f50;
    overflow: hidden;
}
.custom-bg{
    position: absolute;
    left: 50%;
    /* transform: translate3d(-50%, -94.2241px, 0px);
    transform-style: preserve-3d; */
    backface-visibility: hidden;
    height: auto;
    width: 10vw;
}

.offer-list{
    list-style-type: none;
    font-size: 1.4rem;
    li{
        border-radius: 15px;
        width: fit-content;
        padding: 0.5rem;
        display: inline-block;
        margin: 0.5rem;
        box-shadow: 0 5px 12px 0px rgba(var(--mid-grey-), 0.4);
        background-color: rgba(var(--white-), 1);
    }
}
.orange-border li{
    border: 2px solid var(--orange);
}
.green-border li{
    border: 2px solid var(--green);
}
#carousel-wrapper{
    width: 30vw;
    transform: scale(2);
    position: relative;
    left: 8rem;
}
/* #carousel-wrapper div:first-of-type{
    transform: scale(1.4);
} */
.gallery-c-btn{
    background-color: var(--orange);
    color: var(--white);
    transition: 0.6s ease-in-out;
    margin: 2rem 0 2rem 0;
    border-radius: 15px;
    
    &:hover, 
    &:focus{
        background-color: var(--green);
        color: white;
        box-shadow: 0 2px 17px 0 var(--green);
        transition: 0.3s ease-in-out;
    }
}


/* -- Parallax settings -- */
.bcg-parallax{
    padding: 10rem 0;
    color: white;
    background-color: var(--orange);
    background-image: linear-gradient(
        rgba(var(--orange-), 0), 
        rgba($color: $black, $alpha: 0.6)
    );
    text-align: center;
    position: relative;
    overflow: hidden;
}
.bcg{
    /* background: url(../src/resources/images/contour-line.jpg) no-repeat; */
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 140%;
    top: 0;
    z-index: 1;
    opacity: 0.3;
}
.parallax-content-wrapper{
    width: 90%;
    margin: 0 auto;
    max-width: 1140px;
    position: relative;
    z-index: 2;
    h2{
        // margin: 0;
        text-shadow: 0 0 17px var(--mid-grey);
    }
}
.small-parallax-img{
    height: 20rem;
    width: auto;
    /* transition: all 0.3s; */
}

.landing-bcg-parallax{
    height: 100vh;
    padding: 10rem 0;
    color: white;
    background: linear-gradient( 45deg, $yellow-orange, rgba($dark, 0.5) );
    // background: linear-gradient( 90deg, var(--yellow-orange), rgba(var(--orange-), 0.5) );
    display: flex;
    position: relative;
    overflow: hidden;
}
.landing-bcg{
    /* background: url() no-repeat; */
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 140%;
    top: 0;
    z-index: 1;
    opacity: 0.2;
}
.landing-parallax-content-wrapper{
    width: 40vw;
    margin: 0 0 0 20%;
    max-width: 1140px;
    position: relative;
    z-index: 2;
    align-self: center;

    h1 {
        text-shadow: 1px 1px 17px rgba(var(--grey-), 0.5);
    }
}
#parallax2-image {
    width: 100%;
}



/* -- Footer -- */
/* .footer{} */

.footer-top-shape{
    width: 110%;
    transform: translate(-5%, 0);
    margin-top: -5%;
    margin-bottom: -6px;
}
.footer-contents{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 40vh;
    background: #fff;
    padding: 2% 5% 2% 5%;

}
// .footer::before{
//     content: "";
//     background-image: url(resources/images/landing-shape.svg);
//     width: 100%;
//     height: 10vh;
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     transform: translate(10px, -100px);
// }
.footer-contents img{
    height: 10vw;
    max-height: 160px;
    min-height: 120px;
    width: auto;
}
.footer-contents .logo-img{
    opacity: 0.85;
}
.logo-info {margin: 5% 0 5% 0;}
.footer-contents .logo-info p {margin: 0;}
.footer-contents .logo-info p:first-of-type {font-weight: bold;}

.footer-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2% 0 2%;
    p:first-of-type{
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 0;
    }
    p:nth-of-type(2){
        margin-top: 0;
        margin-bottom: 1rem;
    }
}
// .footer-info p:first-of-type{
//     font-size: 1.6rem;
//     font-weight: bold;
//     margin-bottom: 0;
// }
// .footer-info p:nth-of-type(2){
//     margin-top: 0;
//     margin-bottom: 1rem;
// }
.footer-c-btn{
    /* text-transform: none; */
    background-color: var(--green);
    transition: 0.3s ease-in-out;
    margin: 2rem 0 2rem 0;
    border-radius: 15px;
    &:hover {
        /* text-transform: none; */
        background-color: var(--green);
        box-shadow: 0 2px 17px 0 var(--green);
        transition: 0.3s ease-in-out;
    }
}
// .footer-c-btn:hover{
//     /* text-transform: none; */
//     background-color: var(--green);
//     box-shadow: 0 2px 17px 0 var(--green);
//     transition: 0.3s ease-in-out;
// }

.hl-span-after-orange span{
    color: var(--orange);
}
.footer-contacts{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 6rem;
    background-color: white;
    border-top: 1px solid rgba(var(--mid-grey-), 0.25);
    p {
        font-size: 1.5rem;
        border-radius: 10px;
        transition: 0.3s ease-in-out;
        &:hover {
            text-shadow: 0 0 17px var(--green);
            transition: 0.3s ease-in-out;
        }
        a {
            color: var(--grey);
        }
    }
    i {
        color: var(--green);
    }
}
.icon-margin {margin-right: 10px;}
.footer-copyright{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 10% 15px 10%;
    background: #e4e4e4;
    a{
        color: inherit;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        &:hover{
            text-shadow: 0 0 17px var(--grey);
            transition: 0.3s ease-in-out;
        }
    }
}
.copyright {
    margin-right: 2rem;
}
.creator{
    display: flex;
    align-items: center;
    text-align: right;
}
/* .creator a{
    transition: 0.3s ease-in-out;
} */
.creator-logo{
    display: flex;
    height: 2.5rem;
    width: auto;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    &:hover{
        box-shadow: 0 0 17px var(--green);
        background-color: rgba(var(--green-), 0.4);
        transform: scale(1.2) rotate(10deg);
        transition: 0.3s ease-in-out;
    }
}

/* -- CustomerFeedback -- */
.customer-feedback{
    margin: 5rem auto 0 auto;
    padding: 0 5rem 0 5rem;
    width: 100%;
    max-width: 100rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: var(--grey);
    text-align: left;
}
.feedback-btn{
    height: 4rem;
    width: 4rem;
    background-color: var(--white);
    border-radius: 5rem;
    box-shadow: 0 0 17px 0 rgba(var(--mid-grey-), 0.8);
    border: none;
    transition: all 0.3s ease-out;
    &:hover{
        cursor: pointer;
        background-color: rgba(var(--white-), 0.8);
    }
    &:focus, 
    &::selection{
        outline: none;
        background-color: rgba(var(--white-), 0.8);
    }
}
#parallax3 {

    h2{
        margin: 0;
        text-shadow: 0 0 17px var(--mid-grey);
    }
    &.bcg-parallax{padding: 5rem 0;}
    &>.parallax-content-wrapper{
        width: inherit;
        margin: 0;
        max-width: inherit;
    }
} 
.customer-feedback-nav{
    width: 100%;
    max-width: 110rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}
.feedback-btn-prev{
    position: inherit;
    left: 5%;
}
.feedback-btn-next{
    position: inherit;
    right: 5%;
}

/* -- CustomerFeedbackInstance component -- */
.cfi-container {
    max-height: 30rem;
    max-width: 25rem;
    min-width: 21rem;
    padding: 2rem;
    margin: 0 2rem 0 2rem;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 17px 0 rgba(var(--mid-grey-), 0.8);
    display: flex;
    flex-direction: column;
    cursor: default;
    &::before{
        content: "\"";
        font-family: Script MT;
        font-weight: bold;
        color: var(--grey);
        font-size: 11rem;
        position: absolute;
        transform: translate(-5rem, -5.5rem);
    }
}
.avatar-img-container{
    display: flex;
    flex-direction: row;
    border-radius: 50%;
    vertical-align: middle;
    height: 5rem;
    width: 5rem;
    min-width: 5rem;
    margin-right: 1rem;
    border: 1px solid rgba(var(--grey-), 0.5);
    box-shadow: 0 3px 7px rgba(var(--grey-), 0.5);
    overflow: hidden;
    background-color: rgba(var(--orange-), 0.5);
}
.avatar-img{
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
}
.cfi-company-img{
    width: auto !important;
    height: 5rem;
    margin-right: auto;
}
.cfi-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cfi-header-details{
    p {
        margin: 0;
        &:nth-of-type(1) {
            font-weight: bold;
            font-size: 1.3rem;
        }
        &:nth-of-type(2) {
            line-height: 1.2rem;
            font-size: 1rem;
            color: var(--grey);
        }
    }
}
.cfi-feedback{
    white-space: normal;
    overflow-y: auto;
    text-overflow: ellipsis;
    margin-bottom: 0;
    font-size: 1.2rem;
    color: rgba(var(--grey-), 0.8);
}
// .cfi-active{}

// -- SVG --
#home-svg-1, #home-svg-5 {
    // width: 100%;
    // height: 3rem;
    margin: -5.5% auto -2px auto;
    z-index: 100;
}
#home-svg-2 {
    margin: -1px auto -2.5% auto;
    z-index: 100;
}
#home-svg-3 {
    margin: -2% auto -5px auto;
    z-index: 100;
}
#home-svg-4 {
    margin: -1px auto -2.5% auto;
    z-index: 100;
}
#home-svg-6 {
    margin: -1px auto -2.5% auto;
    z-index: 100;
}
#home-svg-7 {
    margin: -1.5% auto -1px auto;
    z-index: 100;
}
#offer-section-wrapper {
    margin-top: -2%;
    color: #CBAC74;
}

// -- LOGO --
.nav-logo-text,
.nav-logo-effects {
    fill: white;
    transition: all 0.3s ease-out;
}
.nav-scrolled .nav-logo-text {
    fill: #2C2B2A;
}
.nav-scrolled .nav-logo-effects {
    fill: #CBAC74;
}

/* -- Prices styling -- */
#price-table-container {
    max-width: 100%;
    min-height: 100vh;
    padding: 10rem 0 3rem 0;
    background-image: linear-gradient(
        rgba(var(--orange-), 0), 
        rgba($color: $black, $alpha: 0.6)
    );
    background-color: rgba(var(--orange-), 0.6);

    &::before {
        content: "";
        position: fixed;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url(resources/images/contour-line.jpg);
        // filter: blur(3px);
        z-index: -10;
    }

    h2 {
        margin: unset;
        color: white;
    }
    #c-price-table-wrapper {
        margin: 5rem auto 10rem auto;
        width: fit-content;
        max-width: 50rem;
        min-width: 20rem;

        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 4px 17px rgba(black, 0.5);


        #c-price-table {
            background: white;
            
            thead {
                font-size: 1.1rem;
                min-height: 3rem;
            }
            th, td {
                padding: 1rem;
                min-width: 8rem;
            }
            th:first-of-type, 
            th:last-of-type, 
            td:first-of-type, 
            td:last-of-type {
                padding: 1rem;
                min-width: 4rem;
            }
            tbody tr:nth-child(odd) {
                background-color: rgba(var(--grey-), 0.1);
            }
        }
    }
}

/* -- ContactUs styling -- */
#contact-us-wrapper {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.6);
    z-index: 1001;

    #contact-us-form-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $white;
        clip-path: circle(100rem at 50% 170%);
        transition: clip-path 0.5 ease-in-out;

        #form-wrapper {
            position: initial;
            z-index: 3000;
        }

        #contact-us-header {
            font-weight: bold;
            margin-bottom: 4rem;
            text-shadow: 0 3px 17px rgba($color: $gold, $alpha: 1);
        }

        form {

            .form-item {
                margin: 0.6rem 0;
            }

            label {
                display: inline-block;
                margin-bottom: 0.2rem;
                font-size: 1.3rem;
                color: $black;
                font-weight: bold;
            }

            input {
                height: 4rem;
                padding: 1rem;
                box-sizing: border-box;
            }
            textarea {
                height: 8rem;
                padding: 1rem;
                resize: vertical;
            }

            input, textarea {
                background: white;
                border-radius: 1rem;
                border: none;
                box-shadow: 0 4px 15px rgba($color: $black, $alpha: 0.6);
            }

            button {
                font-weight: bold;
                border-radius: 1rem;
                background-color: $white;
                color: $black;
                box-shadow: 0 4px 15px rgba($color: $black, $alpha: 0.6);

                &:hover {
                    background-color: rgba($color: $green, $alpha: 1);
                }
            }
        }
    }
    #contact-us-bg-logo {
        position: inherit;
        display: block;
        z-index: 2000;
        max-width: 70rem;
        bottom: 7rem;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.2;
    }
    .contact-us-bg-logo-text {
        fill: $dark;
    }
    .contact-us-bg-logo-effects {
        fill: $gold;
    }

    #close-contact-us {
        position: fixed;
        top: 5rem;
        right: 8rem;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
        text-shadow: 0 0 10px rgba($color: $black, $alpha: 0.6);
    }

}
.show-flex {
    display: flex !important;
}
.anim-clip-path {
    -webkit-animation: anim-clip-path 0.5s backwards;
    animation: anim-clip-path 0.5s backwards;
}

/** MessageBox styling */
.message {
    &.d-block {
        display: block;
    }
    &.d-none {
        display: none;
    }
}
#gallery-message {
    box-shadow: 0 4px 10px rgba($color: $black, $alpha: 0.6);
    padding: 0.5rem 1rem 1rem 1rem;
    border-radius: 1rem;
    button {
        display: flex;
    }
}