@media screen and (max-width: 1199px){
    #contact-us-wrapper {
        #contact-us-form-wrapper {
            clip-path: circle(103rem at 50% 170%);
        }
    }
}

@media screen and (max-width: 992px){
    #nav {
        height: var(--nav-height-m);
    }
    #nav #nav-ul{
        // right: 5% !important;
        // height: var(--nav-height-m) !important;
        // transition: 0.3s ease-out;
        display: none;
    }
    #mobile-bars{
        display: flex;
    }
    #home-shortcut{
        left: 5%;
    }
    // .c-content{
    //     /* padding: 3rem 5% 3rem 5%; */
    // }
    h1{
        font-size: 3.5rem;
    }
    h2{
        font-size: 3rem;
    }
    h3{
        font-size: 2.4rem;
    }
    p, .offer-list{
        font-size: 1.2rem;
    }
    .section-header::after{
        width: 5rem;
        height: 0.4rem;
        transform: translate(-50%, 4rem);
    }
    .landing-parallax-content-wrapper{
        width: 50%;
        margin: 0 0 0 10%;
    }
    .small-parallax-img{
        height: 15rem;
    }
    #about-text-wrapper{
        width: 70vw;
    }
    #about-logo-img-wrapper{
        right: 3rem;
    }
    #about-logo-img{
        opacity: 0.1;
    }
    .bcg-parallax{
        padding: 7rem 0;
    }
    .footer-info p:first-of-type{
        font-size: 1.4rem;
    }
    .footer-contents{
        flex-direction: column;
    }
    .footer-copyright{
        padding: 15px 5% 15px 5%;
    }

    .landing-c-btn, 
    .offer-c-btn, 
    .gallery-c-btn,
    .footer-c-btn{
        border-radius: 10px;
    }

    #gallery-text-wrapper{
        flex-direction: column;
    }
    .carousel{
        transform-origin: center;
    }
    #carousel-wrapper{
        width: 100%;
        margin-top: -5rem;
        overflow: hidden;
        transform: scale(1.5);
        left: unset;
    }
    #carousel-text{
        align-self: center;
        margin-top: -5rem;
        text-align: center;
    }
    #gallery-text{
        width: 50vw;
        margin: 0 auto;
        text-align: left;
    }

    #contact-us-wrapper {
        #contact-us-form-wrapper {
            clip-path: circle(107rem at 50% 170%);
        }
    }
}

@media screen and (max-width: 600px){
    #home-shortcut{
        left: 40px;
    }
    #home-shortcut .logo-img{
        height: 2.5rem;
    }
    #nav {
        height: var(--nav-height-s);
    }
    #nav #nav-ul{
        display: none;
    }
    #mobile-bars{
        display: flex;
    }
    #mobile-menu-container {
        #mobile-nav-link-list {
            li {
                a::after {
                    font-size: 2.5rem;
                }
            }
        }
        #close-mobile-menu {
            height: 9rem;
            width: 9rem;
        }
    }
    //TODO: zmniejszyć czcionkę mobile menu i pomarańczowy kwadrat
    h1{
        font-size: 3rem;
    }
    h2{
        font-size: 2.7rem;
    }
    h3{
        font-size: 2rem;
    }
    p, .offer-list{
        font-size: 1rem;
    }
    .section-header::after{
        width: 4rem;
        height: 0.35rem;
        transform: translate(-50%, 3.5rem);
    }
    .landing-parallax-content-wrapper{
        width: 100%;
        margin: 0 50px 0 50px;
    }
    .small-parallax-img{
        height: 10rem;
    }
    #o{
        padding: 0 10% 0 20%;
    }
    #about-tools-img{
        margin-left: -20%;
        transform: translateX(-43%) rotate(90deg) scale(0.7) scaleX(1.3);
        opacity: 0.8;
    }
    #about-text-wrapper{
        /* width: 100%; */
        margin: 0 5% 0 5%;
    }
    #about-logo-img-wrapper{
        right: 50%;
        transform: translateX(50%);
    }
    #about-logo-img{
        opacity: 0.1;
    }
    #offer-tools-img{
        display: none;
    }
    #offer-text-wrapper{
        width: 100%;
        margin: unset;
        text-align: center;
    }
    #offer-text{
        text-align: left;
    }
    #offer-img-wrapper{
        right: 5%;
        left: unset;
    }
    #offer-img{
        opacity: 0.8;
    }
    .bcg-parallax{
        padding: 3rem 0;

        #parallax1-image,
        #parallax2-image {
            width: 100%;
        }
        #parallax1-image {
            transform: translateY(-15%) !important;
        }
        #parallax2-image {
            transform: translateY(-25%) !important;
        }

    }
    .parallax-content-wrapper {
        h2 {
            font-size: 2.5rem;
            margin: unset;
        }
    }
    .footer-contacts{
        flex-direction: column;
    }
    .footer-contacts p:nth-of-type(2){
        margin-top: 0;
    }
    .footer-copyright{
        /* height: 3rem; */
        font-size: 0.8rem;
    }
    .creator-logo{
        height: 2rem;
    }

    .landing-c-btn, 
    .offer-c-btn,
    .gallery-c-btn, 
    .footer-c-btn{
        border-radius: 5px;
    }

    #carousel-wrapper{
        width: 100%;
        margin-top: -8rem;
        overflow: hidden;
        transform: scale(1);
        left: unset;
    }

    #carousel-text{
        align-self: center;
        text-align: center;
        margin-top: -8rem;
    }
    #gallery-text{
        width: 80vw;
        margin: 0 auto;
        text-align: left;
    }

    #contact-us-wrapper {
        #contact-us-form-wrapper {
            clip-path: circle(45rem at 50% 100%);
            #form-wrapper {
                margin: 0 5%;
            }
            #contact-us-header {
                font-size: 1.5rem;
                margin-bottom: 2rem;
            }
            form {
                label {
                    font-size: 1rem;
                }
                input {
                    height: 3rem;
                    border-radius: 0.5rem;
                }
                textarea {
                    height: 6rem;
                }
                button {
                    font-size: 1rem;
                }
            }
        }
    }
    #price-table-container {
        #c-price-table-wrapper {
            margin: 4rem 5%;
        }
    }
    #close-contact-us {
        top: 3rem !important;
        right: 4rem !important;
    }

}
@keyframes float-left {
    0%{
        transform: translateX(-20%);
        opacity: 0;
    }
}
@keyframes anim-clip-path {
    0%{
        clip-path: circle(0 at 50% 100%);
        // opacity: 0;
    }
}